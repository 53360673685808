import React, { createContext, useContext, useState, useEffect } from 'react';

// Create context
const ProductPricingContext = createContext();

// Create context provider
export const ProductPricingProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [cartSummary, setCartSummary] = useState({
    totalOriginalPrice: 0,
    totalDiscount: 0,
    totalPayable: 0,
  });

  // Update cartSummary whenever products change
  useEffect(() => {
    const totalOriginalPrice = products.reduce(
      (acc, item) => acc + item.originalPrice * item.quantity,
      0
    );
    const totalDiscount = products.reduce(
      (acc, item) =>
        acc +
        (item.originalPrice * item.quantity -
          item.discountedPrice * item.quantity),
      0
    );
    const totalPayable = totalOriginalPrice - totalDiscount;

    setCartSummary({
      totalOriginalPrice,
      totalDiscount,
      totalPayable,
    });
  }, [products]);

  return (
    <ProductPricingContext.Provider
      value={{ products, setProducts, cartSummary, setCartSummary }}
    >
      {children}
    </ProductPricingContext.Provider>
  );
};

// Custom hook for using the context
export const useProductPricingContext = () => {
  return useContext(ProductPricingContext);
};
