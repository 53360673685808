import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './OrderMgmt.css';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Spinner,
  Center,
  Flex,
  Input,
} from '@chakra-ui/react';
import axios from 'axios';
import OrderInfo from './OrderInfo/OrderInfo';

const OrderMgmt = () => {
  const toast = useToast();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8; // Show 8 rows per page

  const handleMoreInfoClick = (order) => {
    setSelectedOrder(order);
  };
  
  const handleCloseOrderInfo = () => {
    setSelectedOrder(null);
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://leatherandstyle.netlify.app/.netlify/functions/getAllOrders');
      if (response.status === 200) {
        let reverseOrders= response.data.reverse();
        setOrders(reverseOrders);
        setFilteredOrders(reverseOrders); 
        console.log('response data:', response.data);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('error in fetching the order details', e);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Filter logic
  useEffect(() => {
    if (fromDate || toDate) {
      const filtered = orders.filter((order) => {
        const orderDate = new Date(order.updatedAt);
        const isAfterFromDate = fromDate ? orderDate >= fromDate : true;
        const isBeforeToDate = toDate ? orderDate <= toDate : true;
        return isAfterFromDate && isBeforeToDate;
      });
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders);
    }
  }, [fromDate, toDate, orders]);

  // Calculate pagination values
  const totalPages = Math.ceil(filteredOrders.length / rowsPerPage);
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    
    <div className="order-mgmt-main-container">
      {loading ? (
        <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
          <Box textAlign="center">
            <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
            <Box mt={4} fontSize="lg" color="gray.600">
              Please wait loading the orders...
            </Box>
          </Box>
        </Center>
      ) : (
        <>
        {selectedOrder ? (
          <OrderInfo
            order={selectedOrder}
            onClose={handleCloseOrderInfo}
            // onUpdateShippingStatus={handleUpdateShippingStatus}
          />
        ) :(
          <>
          <div className="order-mgmt-main-header">Order Management</div>
          {/* Date Filters */}
          <Flex justifyContent="flex-end" mb={4} alignItems="center" style={{marginTop:'2rem'}}>
            
            <Box mr={4}>
              <label style={{marginRight:'0.5rem'}}>From Date:</label>
              <DatePicker
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                isClearable
                placeholderText="Select From Date"
                customInput={<Input />}
                dateFormat="yyyy/MM/dd"
              />
            </Box>
            <Box>
              <label style={{marginRight:'0.5rem'}}>To Date:</label>
              <DatePicker
                selected={toDate}
                onChange={(date) => setToDate(date)}
                isClearable
                placeholderText="Select To Date"
                customInput={<Input />}
                dateFormat="yyyy/MM/dd"
              />
            </Box>
          </Flex>

          {/* Table */}
          <TableContainer className="order-mgmt-table-container">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th style={{ textAlign: 'center' }}>Sr. No.</Th>
                  <Th style={{ textAlign: 'center' }}>Order Id</Th>
                  <Th style={{ textAlign: 'center' }}>Transaction Id</Th>
                  <Th style={{ textAlign: 'center' }}>User Name</Th>
                  <Th style={{ textAlign: 'center' }}>Product Name</Th>
                  <Th style={{ textAlign: 'center' }}>Transaction Status</Th>
                  <Th style={{ textAlign: 'center' }}>Shipping Status</Th>
                  <Th style={{ textAlign: 'center' }}>Amount</Th>
                  <Th style={{ textAlign: 'center' }}>Time</Th>
                  <Th style={{ textAlign: 'center' }}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedOrders.map((order, index) => (
                  <Tr key={order.orderId}>
                    <Td className="order-mgmt-table-row">{(currentPage - 1) * rowsPerPage + index + 1}</Td> {/* Auto-incremented Sr. No */}
                    <Td className="order-mgmt-table-row">{order.orderId}</Td>
                    <Td className="order-mgmt-table-row">{order.mihpayid}</Td>
                    <Td className="order-mgmt-table-row">
                    {order.profile_data?.first_name || 'N/A'} {order.profile_data?.last_name || ''}
                    </Td>
                    <Td className="order-mgmt-table-row">
                      {order.products.map((product, index) => (
                        <React.Fragment key={product.id || index}>
                          {product.name}({product.quantity})
                          <br />
                        </React.Fragment>
                      ))}
                    </Td>
                    <Td className="order-mgmt-table-row">{order.payment.paymentStatus}</Td>
                    <Td className="order-mgmt-table-row">{order.shipping.status}</Td>
                    <Td className="order-mgmt-table-row">₹ {order.payment.amount} /-</Td>
                    <Td className="order-mgmt-table-row">
                      {new Date(order.updatedAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: true,
                      })}
                    </Td>
                    <Td>
                      <Button colorScheme="blue" onClick={()=>handleMoreInfoClick(order)}>More Info</Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Box display="flex" justifyContent="flex-end" mt={4} pr={2}>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button
                key={i + 1}
                size="sm"
                mx={1}
                colorScheme={currentPage === i + 1 ? 'blue' : 'gray'}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Button>
            ))}
          </Box>
          </>
        )}
          
        </>
      )}
    </div>
  );
};

export default OrderMgmt;
