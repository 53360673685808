import React, { useEffect } from 'react';
import { useProductPricingContext } from '../../contexts/ProductPricingContext';
import './ProductSummary.css';

const ProductSummary = ({ productsData }) => {
  const { products, setProducts } = useProductPricingContext();

  useEffect(() => {
    // Initialize products state with productsData
    setProducts(productsData);
  }, [productsData, setProducts]);

  const updateQuantity = (index, newQuantity) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];
      if (newQuantity >= 1 && newQuantity <= updatedProducts[index].stock) {
        updatedProducts[index].quantity = newQuantity;
      }
      return updatedProducts;
    });
  };

  return (
    <div className="product-summary-container">
      <div className="product-summary-header">Product Summary</div>

      {products.map((product, index) => (
        <div className="ps-inner-container" key={index}>
          <div className="ps-summary-product">
            <div className="ps-summary-product-img">
              <img src={product.image} alt="" id="ps-product-img" />
            </div>

            <div className="ps-summary-product-info">
              <div className="ps-summary-product-name">{product.name}</div>
              <div className="ps-summary-product-pricing">
                <p className="product-price">
                  ₹
                  {product.originalPrice -
                    (product.discount / 100) * product.originalPrice}{' '}
                  <span className="cut-price1">Rs {product.originalPrice}/-</span>
                </p>
                <span className="diff-disc1" style={{ textAlign: 'left' }}>
                  {product.discount}% OFF
                </span>
              </div>
              <div className="ps-summary-product-quantity">
                <span style={{ fontWeight: 'bold', textAlign: 'left' }}>
                  Quantity:
                </span>
                <div className="quantity-controls">
                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(index, product.quantity - 1)}
                    disabled={product.quantity <= 1}
                  >
                    -
                  </button>

                  <input
                    type="text"
                    className="quantity-input"
                    value={product.quantity}
                    readOnly
                  />

                  <button
                    className="quantity-btn"
                    onClick={() => updateQuantity(index, product.quantity + 1)}
                    disabled={product.quantity >= product.stock}
                  >
                    +
                  </button>
                </div>

                {product.quantity >= product.stock && (
                  <div className="stock-warning">Stock limit reached</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductSummary;
