import React, { useState, useRef, useEffect } from 'react';
import "./OrderSummaryPage.css";
import Navbar from '../../components/Navbar/Navbar';
import ContactUs from '../../components/ContactUs/ContactUs';
import { Box, Button, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton,Spinner, Center } from '@chakra-ui/react';
import {ArrowBackIcon} from "@chakra-ui/icons"
import { useUser } from '../../contexts/UserProvider';
import { useLocation } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import back from '../../assets/icons/backArrow.png'
import axios from 'axios';
import ProductSummary from './ProductSummary';
import PricingSummary from './PricingSummary';
import { useProductPricingContext  } from '../../contexts/ProductPricingContext';


const OrderSummaryPage = () => {
    const key= process.env.REACT_APP_KEY;
    const [self, setSelf] = useState(null);
    const toast= useToast();
    const navigate= useNavigate();
    const location= useLocation();
    const { fetchUserData, user } = useUser();
    const contactUsRef = useRef(null);
    const { products, setProducts, cartSummary, setCartSummary } = useProductPricingContext();

    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [loading, setLoading]= useState(false);

    // Local states for form fields
    const [first_name, setFirst_name] = useState(user?.first_name || '');
    const [last_name, setLast_name] = useState(user?.last_name || '');
    const [phno, setPhno] = useState(user?.phno || '');
    const [pincode, setPincode] = useState(user?.pincode || '');
    const [locality, setlocality]= useState(user?.locality || '');
    const [address, setAddress] = useState(user?.address || '');
    const [city, setCity]= useState(user?.city || '');
    const [state, setState]= useState(user?.state || '');
    const [landmark, setLandmark]= useState(user?.landmark || '');
    const [altphno, setAltPhno]= useState(user?.alt_phno || '');
    const [hash, setHash]= useState('');
    const [txnid, setTxnid]= useState('');
    const [productInfo, setProductInfo]= useState([]);
    const [showBarDetails, setShowBarDetails]= useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false); 

    const allDetailsProvided =
    first_name &&
    last_name &&
    phno &&
    pincode &&
    locality &&
    address &&
    city &&
    state;

    const toggleEditing = () => {
      if (isEditing) {
 
        setIsAnimating(true);
        setTimeout(() => {
          setIsEditing(false);
          setIsAnimating(false); 
          window.scrollTo({top:0,   behavior: 'smooth'})
        }, 500);
  
      } else {
        setIsEditing(true); 
      }
    };

    useEffect(() => {
      const fromProductDescription = location?.state;
      if (fromProductDescription) {
        const product = {
          id: fromProductDescription.productId,
          name: fromProductDescription.product_name,
          quantity: 1,
          originalPrice: fromProductDescription.product_price,
          stock: fromProductDescription.product_stock,
          discount: fromProductDescription.product_discount,
          discountedPrice:
            fromProductDescription.product_price -
            (fromProductDescription.product_price *
              fromProductDescription.product_discount) /
              100,
          image: fromProductDescription.product_mainImage,
        };
        setProducts([product]);
      } else {
        const cartItems =
          JSON.parse(localStorage.getItem('cartData')) || [];
        setProducts(
          cartItems.map((item) => ({
            id: item.id,
            name: item.pname,
            quantity: item.quantity,
            originalPrice: item.cut_price,
            discountedPrice: item.price,
            discount: item.discount,
            stock: item.stock,
            image: item.mainImage,
          }))
        );
      }
    }, [location, setProducts]);





      const handleProceedToPayment = async (event) => {
        event.preventDefault();
        
        // Validate phone number
        if (phno.length !== 10) {
          toast({
            title: "Invalid Phone Number",
            description: "Please enter a valid phone number",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          return;
        }
        
        try {
          setLoading(true);
          // Prepare the product information
          const productinfo = products.map(product => ({
            id: product.id,
            name: product.name,
            quantity: product.quantity,
            pricePerUnit: product.originalPrice,
            discountPerUnit: product.discount,
            total: (product.quantity * product.originalPrice) - ((product.quantity * product.originalPrice) * (product.discount / 100)) // Calculate total for the product
          }));
          
          // Store product info in the state
          setProductInfo(productinfo);
          // Prepare the order data to be sent to the backend
          if(!user){
            await fetchUserData();
          }
          const orderData = {
            user: {
              id: user.id,
              email: user?.email,
              first_name: user.first_name,
              last_name: user.last_name,
            },
            products: productinfo,
            shipping: {
              phone: phno,
              firstName: user.first_name,
              lastName: user.last_name,
              pincode: pincode,
              locality: locality,
              address: address,  
              city: city,
              state: state,
              landmark: landmark,
              altphno: altphno
            },
            paymentDetails: {
              amount: (cartSummary.totalPayable + 40).toFixed(2),
            },
          };
      
          // Step 1: Send the order data to the backend to store the order
          console.log("Sending order data to the backend...");
          const orderResponse = await axios.post("http://leatherandstyle.netlify.app/.netlify/functions/createOrder", orderData);
          setTxnid(orderResponse.data.txnid);
      
          if (orderResponse.data.success) {
            console.log("Order stored successfully, proceeding to generate hash...");
    
            // Step 2: Once the order is stored, call the generate hash API
            const paymentData = {
              productinfo: productinfo,
              amount: (cartSummary.totalPayable + 40).toFixed(2),
              email: user.email,
              firstname: user.first_name,
              lastname: user.last_name,
              phone: phno,
              pincode: pincode,
              locality: locality,
              address: address,  
              city: city,
              state: state,
              landmark: landmark,
              altphno: altphno,
              txnid: orderResponse.data.txnid
            };
            
            const hashResponse = await axios.post("http://leatherandstyle.netlify.app/.netlify/functions/generateHash", paymentData);
            if(hashResponse.status===200){
              setLoading(false);
              const hash = hashResponse.data.hash;
              setHash(hash);
              setSelf(hashResponse.data);
              setIsModalOpen(true);
            }
            
          } else {
            toast({
              title: "Order Error",
              description: "Failed to store the order. Please try again.",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        } catch (err) {
          console.error(err);
          toast({
            title: "Payment Error",
            description: `Failed to initiate payment. Please try again., ${err}`,
            status: "error",
            duration: 10000,
            isClosable: true,
          });
        }
      };
      

    
    return (
        <div className='main-ordersummary-page-container'>
            <Navbar contactUsRef={contactUsRef} />
            {
              loading ? (
                <Center height="100vh" background="rgba(255, 255, 255, 0.9)">
                  <Box textAlign="center">
                      <Spinner size="xl" thickness="4px" speed="0.65s" color="blue.500" />
                      <Box mt={4} fontSize="lg" color="gray.600">
                          Please wait processing your order...
                          Do not refresh the page.
                      </Box>
                  </Box>
              </Center>
              ): (
                <>
                  <div className='ordersummary-page-container'>
                    <div className='inner-container'>
                      <Button onClick={()=>{navigate(-1)}} className='back-icon-button'>
                      <div className="back-icon" >
                    
                        <img src={back} alt="" />
                    
                      </div>
                      </Button>
                      <div className="ic-header">
                        Order Summary
                      </div>
                      <div className="rest-container">
                        <div className='container-header-os'>
                          Delivery Details
                        </div>
                        <div className={`details-bar ${isEditing ? "expanded" : ""}${
                          isAnimating ? "collapsing" : ""
                        }`}>
                        <div className="details-bar-area">
                        <div className="user-details">
                          <div className="user-details-text">
                            <div className="ud-header" style={{fontWeight:'bold', textAlign:'left'}}>Deliver To:</div>
                            
                            {!isEditing ? (
                                <>
                                <div className="ud-user-data">
                                {allDetailsProvided ? (
                                    <>
                                      <span>
                                        {first_name} {last_name}
                                        {phno && `, 91+ ${phno}`}
                                      </span>
                                      <span>
                                        {address && `${address}`}
                                        {locality && `, ${locality}`}
                                        {landmark && `, ${landmark}`}
                                        {city && `, ${city}`}
                                        {state && `, ${state}`}
                                        {pincode && `, ${pincode}`}
                                        {altphno && `, 91+ ${altphno}`}
                                      </span>
                                    </>
                                  ) : (
                                    <span className="warning-text">
                                      Full delivery details not provided. Click Edit to fill in the details.
                                    </span>
                                  )}

                               
                                </div>

                                </>
                              ) : (
                                <>
                                </>
                                
                              )}
                              
                            
                          </div>
                        </div>
                        <div className="bar-buttons">
                          <button
                            className={`edit-button ${isEditing ? "disabled": ""}`}
                            onClick={toggleEditing}
                            disabled={isEditing}
                          >
                            Edit
                          </button>
                        </div>
                        </div>
                        <div>
                        {isEditing && (
                       <div className="delivery-details-area">
                       <div className="name-inputs-container">
                         <div className="input-container">
                           <input type="text" id="first-name" className="name-input" placeholder=" " value={first_name} onChange={(e)=>{setFirst_name(e.target.value)}} required/>
                           <label htmlFor="first-name" className="placeholder-label">First Name</label>
                         </div>
                         <div className="input-container">
                           <input type="text" id="last-name" className="name-input" placeholder=" "  value={last_name} onChange={(e)=>{setLast_name(e.target.value)}} required/>
                           <label htmlFor="last-name" className="placeholder-label">Last Name</label>
                         </div>
                       </div>

                       <div className="phno-container">
                         <div className="input-container1">
                           <div className="phno-prefix">91+</div>
                           <div className="divider-line"></div>
                           <input type="number" id="phone-number" className="phno-input" placeholder=" " value={phno} onChange={(e)=>{setPhno(e.target.value)}} required/>
                           <label htmlFor="phone-number" className="placeholder-label1">Phone Number</label>
                         </div>
                       </div>

                     {/* Residential Address Section */}
                       <div className="address-section">
                         {/* Pincode and Locality */}
                         <div className="row">
                           <div className="input-container">
                             <input type="number" id="pincode" className="name-input" placeholder=" "  value={pincode} onChange={(e)=>{setPincode(e.target.value)}}/>
                             <label htmlFor="pincode" className="placeholder-label">Pincode</label>
                           </div>
                           <div className="input-container">
                             <input type="text" id="locality" className="name-input" placeholder=" " value={locality} onChange={(e)=>{setlocality(e.target.value)}}/>
                             <label htmlFor="locality" className="placeholder-label">Locality</label>
                           </div>
                         </div>

                         {/* Address (Area & Street) */}
                         <div className="text-area-container">
                           <textarea id="address" className="address-textarea" placeholder=" " value={address} onChange={(e)=>{setAddress(e.target.value)}}></textarea>
                           <label htmlFor="address" className="placeholder-label">Address (Area & Street)</label>
                         </div>

                         {/* City/District/Town and State */}
                         <div className="row">
                           <div className="input-container">
                             <input type="text" id="city" className="name-input" placeholder=" "  value={city} onChange={(e)=>{setCity(e.target.value)}}/>
                             <label htmlFor="city" className="placeholder-label">City/District/Town</label>
                           </div>
                         <div className="input-container">
                           <select id="state" className="name-input" value={state} onChange={(e)=>{setState(e.target.value)}}>
                             <option value="" disabled selected>Select State</option>
                             <option value="Andhra Pradesh">Andhra Pradesh</option>
                             <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                             <option value="Assam">Assam</option>
                             <option value="Bihar">Bihar</option>
                             <option value="Chhattisgarh">Chhattisgarh</option>
                             <option value="Delhi">Delhi</option>
                             <option value="Goa">Goa</option>
                             <option value="Gujarat">Gujarat</option>
                             <option value="Haryana">Haryana</option>
                             <option value="Himachal Pradesh">Himachal Pradesh</option>
                             <option value="Jharkhand">Jharkhand</option>
                             <option value="Karnataka">Karnataka</option>
                             <option value="Kerala">Kerala</option>
                             <option value="Madhya Pradesh">Madhya Pradesh</option>
                             <option value="Maharashtra">Maharashtra</option>
                             <option value="Manipur">Manipur</option>
                             <option value="Meghalaya">Meghalaya</option>
                             <option value="Mizoram">Mizoram</option>
                             <option value="Nagaland">Nagaland</option>
                             <option value="Odisha">Odisha</option>
                             <option value="Punjab">Punjab</option>
                             <option value="Rajasthan">Rajasthan</option>
                             <option value="Sikkim">Sikkim</option>
                             <option value="Tamil Nadu">Tamil Nadu</option>
                             <option value="Telangana">Telangana</option>
                             <option value="Tripura">Tripura</option>
                             <option value="Uttar Pradesh">Uttar Pradesh</option>
                             <option value="Uttarakhand">Uttarakhand</option>
                             <option value="West Bengal">West Bengal</option>
                           </select>
                           <label htmlFor="state" className="placeholder-label">State</label>
                         </div>
                       </div>

                       {/* Landmark and Alternate Phone */}
                       <div className="row">
                         <div className="input-container">
                           <input type="text" id="landmark" className="name-input" placeholder=" " value={landmark} onChange={(e)=>{setLandmark(e.target.value)}}/>
                           <label htmlFor="landmark" className="placeholder-label">Landmark (Optional)</label>
                         </div>
                         <div className="input-container">
                           <input type="number" id="alternate-phone" className="name-input" placeholder=" " value={altphno} onChange={(e)=>{setAltPhno(e.target.value)}}/>
                           <label htmlFor="alternate-phone" className="placeholder-label">Alternate Phone (Optional)</label>
                         </div>
                       </div>
                     </div>
                     <div className="row">
                    <button className="save-button" onClick={toggleEditing}>
                      Save
                    </button>
                  </div>
                   </div>
                      )}
                  </div>
                  </div>

                      
                    {/* </div> */}

                      <ProductSummary productsData={products}/>
                      <PricingSummary />
                     
                      <div className='confirmandpay' style={{display: 'flex', flexDirection:'column'}}>
                      {!allDetailsProvided && (
                          <p className="error-text">
                            Provide all required delivery details.
                          </p>
                        )}
                        <div style={{display: 'flex' , gap:'0.5rem'}}>
                 
                        <Button style={{color:'white', backgroundColor:'rgb(40,109,175,100)'}}     disabled={!allDetailsProvided} onClick={handleProceedToPayment}>
                          Confirm and Pay
                        </Button>
                        <Button style={{border:'1px black solid'}} onClick={()=>{navigate(-1)}}>
                          Cancel
                        </Button>
                                 
                        </div>
                      </div>
                      
                      </div>

                    </div>

                  </div>
                
                
            
                  <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                      <ModalOverlay />
                      <ModalContent className='final-modal-content'>
                          <ModalHeader fontFamily='Gowun Batang, serif'>Order Summary</ModalHeader>
                          <ModalCloseButton />
                          <ModalBody>
                          <div className="modal-delivery-info">
                            <p><strong>Deliver To: {first_name} {last_name}</strong></p>
                            <p>
                              {phno && `Mob: 91+ ${phno}`}
                              {address && `, ${address}`}
                              {locality && `, ${locality}`}
                              {landmark && `, ${landmark}`}
                              {city && `, ${city}`}
                              {state && `, ${state}`}
                              {pincode && `, ${pincode}`}
                              {altphno && `, Alt Mob: 91+ ${altphno}`}
                            </p>
                          </div>

                          <div className="modal-products-info">
                            <h3 className="products-summary-header">Product Summary</h3>
                            {products.map((product, index) => (
                              <div className="product-item" key={index}>
                                <div className="product-image">
                                  <img src={product.image} alt={product.name} />
                                </div>
                                <div className="product-details">
                                  <h4 className="product-name">{product.name}</h4>
                                  <p className="product-quantity">Quantity: {product.quantity}</p>
                                  <div className="product-pricing">
                                    <p className="discounted-price">
                                      ₹{product.originalPrice - (product.discount / 100) * product.originalPrice}
                                    </p>
                                    <p className="original-price">₹{product.originalPrice}</p>
                                    <p className="product-discount">{product.discount}% OFF</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="modal-price-summary">
                            <h3 className="price-summary-header">Product Summary</h3>
                            <div className="summary-row">
                              <span className="label">Price:</span>
                              <span className="value">₹{cartSummary.totalOriginalPrice}</span>
                            </div>
                            <div className="summary-row">
                              <span className="label">Discount:</span>
                              <span className="value">-₹{cartSummary.totalDiscount}</span>
                            </div>
                            <div className="summary-row">
                              <span className="label">Shipping Charges:</span>
                              <span className="value">₹40</span>
                            </div>
                            <hr className="summary-divider" />
                            <div className="summary-row total">
                              <span className="label">Total Amount:</span>
                              <span className="value">₹{cartSummary.totalPayable + 40}</span>
                            </div>
                          </div>
                        </ModalBody>
                          <ModalFooter className='modal-footer-submit'>
                              <form action="https://secure.payu.in/_payment" method="post">
                                  <input type="hidden" name="key" value={key} />
                                  <input type="hidden" name="txnid" value={txnid} />
                                  <input type="hidden" name="productinfo" value={productInfo} />
                                  <input type="hidden" name="amount" value={(cartSummary.totalPayable + 40).toFixed(2)} />
                                  <input type="hidden" name="email" value={user?.email} />
                                  <input type="hidden" name="firstname" value={user?.first_name} />
                                  <input type="hidden" name="lastname" value={user?.last_name} />
                                  <input type="hidden" name="surl" value="http://leatherandstyle.netlify.app/.netlify/functions/paymentSuccess" />
                                  <input type="hidden" name="furl" value="http://leatherandstyle.netlify.app/.netlify/functions/paymentFailure" />
                                  <input type="hidden" name="phone" value={phno} />
                                  <input type="hidden" name="udf1" value={9967} />
                                  <input type="hidden" name="udf2" value={2963} />
                                  <input type="hidden" name="udf3" value={8599} />
                                  <input type="hidden" name="udf4" value={8789} />
                                  <input type="hidden" name="udf5" value={8988} />
                                  <input type="hidden" name="hash" value={hash} />
                                  <div style={{display:'flex', gap: '0.5rem'}}>
                                  <Button colorScheme="blue" type="submit" >Pay Now</Button>
                                  <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Close</Button>
                                  </div>
                              </form>
                          </ModalFooter>
                      </ModalContent>
                  </Modal>
            </>
              )
            }
            <div ref={contactUsRef} style={{marginTop: '6rem'}}>
                <ContactUs />
            </div>
                
        </div>
    );
};

export default OrderSummaryPage;
