import './PricingSummary.css';
import React, {useEffect, useState} from 'react';
import { useProductPricingContext  } from '../../contexts/ProductPricingContext';
const PricingSummary = () => {
      const { cartSummary, setCartSummary } = useProductPricingContext();
      const[cartSummaryKeys, setCartSummaryKeys]=useState([]);
  useEffect(()=>{
    console.log('cartSummary', cartSummary);
    const keys = Object.keys(cartSummary);
    setCartSummaryKeys(keys);
  }, [])
  return (
    <div className="pricing-summary-main-container">
      <div className="pricing-summary-header">
        Pricing Summary
      </div>
      <div className="pricing-summary-details">
        <div className="pricing-summary-row">
          <span>Price</span>
          <span>₹ {cartSummary[cartSummaryKeys[0]]}</span>
        </div>
        <div className="pricing-summary-row">
          <span>Discount</span>
          <span>- ₹{cartSummary[cartSummaryKeys[1]]}</span> 
        </div>
        <div className="pricing-summary-row">
          <span>Shipping charges</span>
          <span>₹40</span>
        </div>
        <hr className='divider-liner'/>
        <div className="pricing-summary-row total">
          <span>Total Amount</span>
          <span>₹{cartSummary[cartSummaryKeys[2]]+40}</span>
        </div>
      </div>
    </div>
  );
};

export default PricingSummary;