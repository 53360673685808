import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Toast,
} from '@chakra-ui/react';
import './CorporateGiftsReq.css';
import axios from 'axios';

const CorporateGiftsReq = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [newRequests, setNewRequests] = useState([]);
  const [reviewedRequests, setReviewedRequests] = useState([]);
  const [displayedNewRequests, setDisplayedNewRequests] = useState(10);
  const [displayedReviewedRequests, setDisplayedReviewedRequests] = useState(10);

  
  const fetchRequests = async () => {
    try {
      const response = await axios.get('http://leatherandstyle.netlify.app/.netlify/functions/getCGRequests');
      const data = response.data;
      console.log('CGR:', data);

      const newReqs = data.filter(req => req.isReviewed === 0);
      const reviewedReqs = data.filter(req => req.isReviewed === 1);

      setNewRequests(newReqs);
      setReviewedRequests(reviewedReqs);
    } catch (e) {
      console.log('Error in fetching CGR from backend:', e);
    }
  };


  const handleChangeStatus = async (status) => {
    try {
      if (!selectedRequest?._id) {
        console.log("No request selected");
        return;
      }
  
      const body = {
        id: selectedRequest._id, 
        status: status, 
      };
  
      const response = await axios.post(
        "http://leatherandstyle.netlify.app/.netlify/functions/updateCGRequests",
        body
      );
  
      if (response.status === 200) {
        console.log("Status updated");
  
     
        fetchRequests();
        onClose();
      }
    } catch (e) {
      console.log("Error in changing status of the selected request", e);
    }
  };
  



  useEffect(() => {
    fetchRequests();
  }, []);

  // Handle Request Click
  const handleRequestClick = (request, type) => {
    setSelectedRequest({ ...request, type });
    onOpen();
  };


  

  // Show More/Less Logic
  const handleShowMoreNew = () => {
    setDisplayedNewRequests(prev => prev + 10);
  };

  const handleShowLessNew = () => {
    setDisplayedNewRequests(10);
  };

  const handleShowMoreReviewed = () => {
    setDisplayedReviewedRequests(prev => prev + 10);
  };

  const handleShowLessReviewed = () => {
    setDisplayedReviewedRequests(10);
  };

  return (
    <Box className="CGR-main-container">
      <div className="CGR-main-header">Corporate Gift Requests</div>

      <Tabs isFitted variant="enclosed" className="CGR-tabs">
        <TabList mb="1em">
          <Tab>New Requests</Tab>
          <Tab>Reviewed Requests</Tab>
        </TabList>

        {/* New Requests */}
        <TabPanels>
          <TabPanel>
            {newRequests.slice(0, displayedNewRequests).map(req => (
              <Box key={req._id} className="request-bar" onClick={() => handleRequestClick(req, 'new')}>
                <span>{req.company}</span>
              </Box>
            ))}
            {newRequests.length > 10 && (
              <div className="show-buttons">
                {displayedNewRequests < newRequests.length ? (
                  <Button onClick={handleShowMoreNew} colorScheme="blue" size="sm" mt="2">
                    Show More
                  </Button>
                ) : (
                  <Button onClick={handleShowLessNew} colorScheme="red" size="sm" mt="2">
                    Show Less
                  </Button>
                )}
              </div>
            )}
          </TabPanel>

          {/* Reviewed Requests */}
          <TabPanel>
            {reviewedRequests.slice(0, displayedReviewedRequests).map(req => (
              <Box key={req._id} className="request-bar" onClick={() => handleRequestClick(req, 'reviewed')}>
                <span>{req.company}</span>
              </Box>
            ))}
            {reviewedRequests.length > 10 && (
              <div className="show-buttons">
                {displayedReviewedRequests < reviewedRequests.length ? (
                  <Button onClick={handleShowMoreReviewed} colorScheme="blue" size="sm" mt="2">
                    Show More
                  </Button>
                ) : (
                  <Button onClick={handleShowLessReviewed} colorScheme="red" size="sm" mt="2">
                    Show Less
                  </Button>
                )}
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Modal for Request Details */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedRequest?.company}</ModalHeader>
          <ModalBody>
            <Text><b>First Name:</b> {selectedRequest?.first_name}</Text>
            <Text><b>Last Name:</b> {selectedRequest?.last_name}</Text>
            <Text><b>Email:</b> {selectedRequest?.email}</Text>
            <Text><b>Phone:</b> {selectedRequest?.phno}</Text>
            <Text><b>Country:</b> {selectedRequest?.country}</Text>
            <Text><b>Product:</b> {selectedRequest?.product_name}</Text>
            <Text><b>Quantity:</b> {selectedRequest?.quantities}</Text>
            <Text><b>Message:</b> {selectedRequest?.message}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button 
              onClick={() => handleChangeStatus(selectedRequest?.type === 'new' ? 1 : 0)} 
              colorScheme={selectedRequest?.type === 'new' ? 'green' : 'orange'}
            >
              {selectedRequest?.type === 'new' ? 'Mark as Reviewed' : 'Mark as Unread'}
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CorporateGiftsReq;
