import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Heading,
  Text,
  Stack,
  Divider,
  Grid,
  GridItem,
  useBreakpointValue,
} from '@chakra-ui/react';
import InvoiceDownload from '../../OrderSummaryPage/InvoiceDownload';

const OrderInfo = ({ order, onClose, onUpdateShippingStatus }) => {
  const [shippingStatus, setShippingStatus] = useState(order.shipping.status);
  const [invoiceData, setInvoiceData] = useState(null);
  const products= order.products;
  const totalAmount = products.reduce((sum, product) => sum + (product.pricePerUnit || 0) * (product.quantity || 0), 0);
  const totalDiscount = products.reduce((sum, product) => sum + ((product.discountPerUnit || 0) / 100) * (product.pricePerUnit || 0) * (product.quantity || 0), 0);
  const totalPayable = totalAmount - totalDiscount + 40;

  const orderId= order.orderId;
  const mihpayid= order.mihpayid;
  const updatedAt= order.updatedAt;
  const shippingDetails= {
    "first_name":order.shipping.firstName,
    "last_name":order.shipping.lastName,
    "phone": order.shipping.phone,
    "address": order.shipping.address,
  };


  // Set the data for the invoice
  useEffect(()=>{
    setInvoiceData({
      orderId,
      mihpayid,
      updatedAt,
      shippingDetails,
      products,
      totalAmount,
      totalDiscount,
      totalPayable,
    });

  }, [])
  


  // Handle dropdown change
  const handleStatusChange = (event) => {
    setShippingStatus(event.target.value);
  };

  // Handle Save Button
  const handleSave = () => {
    if (onUpdateShippingStatus) {
      onUpdateShippingStatus(order.orderId, shippingStatus);
    }
  };

  // Responsive layout based on screen size
  const gridTemplateColumns = useBreakpointValue({ base: '1fr', md: 'repeat(2, 1fr)' });

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="md" mx="auto" width="90%">
      <VStack align="start" spacing={4}>
        {/* Heading */}
        <Heading size="lg" mb={4}>
          Order Details
        </Heading>

        {/* Profile Data */}
        <Box width="100%">
          <Heading size="md" mb={2}>
            Profile Data
          </Heading>
          <Grid templateColumns={gridTemplateColumns} gap={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Full Name</FormLabel>
                <Input value={`${order.profile_data.first_name || ''} ${order.profile_data.last_name}`} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input value={order.profile_data.email} isReadOnly />
              </FormControl>
            </GridItem>
          </Grid>
        </Box>

        <Divider />

        {/* Shipping Data */}
        <Box width="100%">
          <Heading size="md" mb={2}>
            Shipping Data
          </Heading>
          <Grid templateColumns={gridTemplateColumns} gap={4}>
            <GridItem>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input value={order.shipping.firstName} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input value={order.shipping.lastName} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Phone</FormLabel>
                <Input value={order.shipping.phone} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Address</FormLabel>
                <Input value={order.shipping.address} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <FormControl>
                <FormLabel>Status</FormLabel>
                <Select value={shippingStatus} onChange={handleStatusChange}>
                  <option value="new">New</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                </Select>
              </FormControl>
            </GridItem>
          </Grid>
        </Box>

        <Divider />

        {/* Payment Data */}
        <Box width="100%">
          <Heading size="md" mb={2}>
            Payment Data
          </Heading>
          <Grid templateColumns={gridTemplateColumns} gap={4}>
            <GridItem>
              <FormControl>
                <FormLabel>Amount</FormLabel>
                <Input value={`₹ ${order.payment.amount}`} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Transaction Id</FormLabel>
                <Input value={order.payment.transactionId || 'N/A'} isReadOnly />
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl>
                <FormLabel>Payment Status</FormLabel>
                <Input value={order.payment.paymentStatus} isReadOnly />
              </FormControl>
            </GridItem>
          </Grid>
        </Box>

        <Divider />

        {/* Products Data */}
        <Box width="100%">
          <Heading size="md" mb={2}>
            Products
          </Heading>
          {order.products.map((product, index) => (
            <Box key={product.id || index} p={2} borderWidth={1} borderRadius="md" mb={2}>
              <Text><b>Name:</b> {product.name}</Text>
              <Text><b>Quantity:</b> {product.quantity}</Text>
              <Text><b>Price Per Unit:</b> ₹ {product.pricePerUnit}</Text>
              <Text><b>Discount Per Unit:</b> {product.discountPerUnit} %</Text>
              <Text><b>Total:</b> ₹ {product.total}</Text>
            </Box>
          ))}
        </Box>

        <Box width="100%">
          <InvoiceDownload invoiceData={invoiceData}/>
        </Box>

        {/* Actions */}
        <Stack direction="row" spacing={4} mt={4}>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};

export default OrderInfo;
