import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComingSoon from './pages/ComingSoon/ComingSoon.jsx';
import HomePage from './pages/HomePage/HomePage.jsx';
import Login from './pages/Login/Login.jsx';
import Register from './pages/Register/Register.jsx';
import Recovery from './pages/Recovery/Recovery.jsx';
import Profile from './pages/Profile/Profile.jsx';
import CorporateGifts from './pages/CorporateGifts/CorporateGifts.jsx';
import AboutUs from './pages/AboutUs/AboutUs.jsx';
// import WomensBags from './pages/FullShop/WomensBags/WomensBags.jsx';
// import MensBags from './pages/FullShop/MensBags/MensBags.jsx';
// import Accessories from './pages/FullShop/Accessories/Accessories.jsx';
// import TravelBags from './pages/FullShop/TravelBags/TravelBags.jsx';
// import Apparels from './pages/FullShop/Apparels/Apparels.jsx';
// import Giftwares from './pages/FullShop/Giftwares/Giftwares.jsx';
import ProductDescription from './pages/ProductDescription/ProductDescription.jsx';
import Cart from './pages/Cart/Cart.jsx';
import AdminLogin from './pages/AdminLogin/AdminLogin.jsx';
import AdminDashboard from './pages/AdminPage/AdminPage.jsx';
import WpLink from './pages/WpLink/WpLink.jsx';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './utils/AuthContext.js'
import { CartProvider } from './pages/ProductDescription/UseCart';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FullShop from './pages/FullShop/FullShop.jsx';
import OrderSummary from './pages/OrderSummaryPage/OrderSummaryPage.jsx';
import {UserProvider} from './contexts/UserProvider.js';
import PaymentSucess from './pages/OrderSummaryPage/PaymentSuccess.jsx';
import PaymentFailure from './pages/OrderSummaryPage/PaymentFailure.jsx'
import PasswordRecoveryComponent from './pages/Recovery/PasswordRecoveryComponent.jsx'

import {ProductPricingProvider} from './contexts/ProductPricingContext.js';
import './index.css';




const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  return (
    <div className="App">
        <ChakraProvider>
          {/* <ThemeProvider theme={theme}> */}
          <ProductPricingProvider>
            <AuthProvider>
              <UserProvider>
                <CartProvider>
                  <Router>
                    <Routes>
                      <Route path="/" element={<HomePage />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/register" element={<Register />} />
                      <Route path="/recovery" element={<Recovery />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/corporategifts" element={<CorporateGifts />} />
                      <Route path="/aboutus" element={<AboutUs />} />
                      {/* <Route path="/womensbags" element={<WomensBags />} />
                      <Route path="/mensbags" element={<MensBags />} />
                      <Route path="/accessories" element={<Accessories />} />
                      <Route path="/travelbags" element={<TravelBags />} />
                      <Route path="/apparels" element={<Apparels />} />
                      <Route path="/giftwares" element={<Giftwares />} /> */}
                      <Route path="/products" element={<ProductDescription />} />
                      <Route path="/buynow" element={<WpLink />} />
                      <Route path="/cart" element={<Cart />} />
                      <Route path="/admin" element={<AdminLogin />} />
                      <Route path="/LSESPM@467adminPage" element={<AdminDashboard />} />
                      <Route path="/full-shop" element={<FullShop />} />
                      <Route path="/orderSummary" element={<OrderSummary />} />
                      <Route path="/paymentSuccessful" element={<PaymentSucess />} />
                      <Route path="/paymentFail" element={<PaymentFailure />} />
                      <Route path="/recoverpassword" element={<PasswordRecoveryComponent />} />
                    </Routes>
                  </Router>
                </CartProvider>
              </UserProvider>
            </AuthProvider>
          </ProductPricingProvider>
          {/* </ThemeProvider> */}
        </ChakraProvider>
    </div>
  );
}

export default App;
